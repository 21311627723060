<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" text v-bind="attrs" v-on="on">
          パスワード再設定
        </v-btn>
      </template>
      <v-card v-show="!done">
        <v-card-title> </v-card-title>
        <v-card-text class="pb-0">
          <v-container>
            <v-row class="mb-3">
              以下宛にパスワード再設定用URLを送信します。
            </v-row>
            <v-row>
              <v-text-field
                v-model="id"
                label="あなたのSFLメールアドレス"
              ></v-text-field>
            </v-row>
          </v-container>
        </v-card-text>
        <v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="loading"
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              キャンセル
            </v-btn>
            <v-btn
              :loading="loading"
              :disabled="loading"
              color="blue darken-1"
              text
              @click="resetPassword()"
            >
              送信
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
      <v-card v-show="done">
        <v-card-title>
          <v-card-text>
            {{ msg }}
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="clear()"> OK </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card-title>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    id: "",
    dialog: false,
    loading: false,
    done: false,
    msg: "",
    apiResult: "",
  }),
  methods: {
    resetPassword() {
      this.loading = true;
      axios
        .post(process.env.VUE_APP_API_URL + "/reset-url-create", {
          id: this.id,
        })
        .then((response) => {
          this.apiResult = response.data.result;
          this.msg = response.data.msg;
          setTimeout(() => (this.done = true), 3000);
        });
    },
    clear() {
      if (this.apiResult == "OK") {
        this.id = "";
        this.dialog = false;
        this.loading = false;
        this.done = false;
        this.msg = "";
        this.apiResult = "";
      } else {
        this.loading = false;
        this.done = false;
        this.msg = "";
        this.apiResult = "";
      }
    },
  },
};
</script>